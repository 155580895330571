import React from "react";
import { pricing } from "../string";
import { pricingImageStyle, pricingTextStyle } from "../landingPageStyle";

class Pricing extends React.Component {
  render() {
    return (
      <div
        style={{
          minHeight: "46rem",
          position: "relative",
          opacity: 0.9,
          background: "linear-gradient(163.89deg, #DBE2DB 0%, #C6B5B5 100%)",
        }}
      >
        <img
          style={pricingImageStyle}
          src={pricing.image}
          alt={pricing.image}
        />
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            marginLeft: "20rem",
            marginTop: "6rem",
          }}
        >
          <div style={pricingTextStyle.title}>{pricing.title}</div>
          <div style={pricingTextStyle.content}>{pricing.content}</div>
          <div style={pricingTextStyle.point}>{pricing.points}</div>
        </div>
      </div>
    );
  }
}

export { Pricing };
