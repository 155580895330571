import React from "react";
import { IconSVG } from "@aim-mf/styleguide";
import { key_feature, features } from "../string";
import { featureStyle, keyFeatureStyle } from "../landingPageStyle";

class Feature extends React.Component {
  render() {
    return (
      <div>
        <Types />
        <Integration />
        <div style={{ height: "100%", backgroundColor: "rgb(255,255,255)" }}>
          <KeyFeatures />
        </div>
      </div>
    );
  }
}

const Integration = () => {
  return (
    <div style={{ position: "relative", backgroundColor: "#DDE2E9" }}>
      <img
        style={featureStyle.second.image}
        src={features.second.image}
        alt={features.second.image}
      />
      <div
        style={{
          position: "absolute",
          width: "55rem",
          left: 0,
          top: 0,
          marginLeft: "15rem",
          marginTop: "8.67rem",
        }}
      >
        <div style={featureStyle.second.header}>{features.second.header}</div>
        <div style={featureStyle.second.content}>{features.second.content}</div>
      </div>
    </div>
  );
};

const Types = (props) => {
  return (
    <div style={{ position: "relative" }}>
      <div style={featureStyle.first.header}>{features.first.header}</div>
      <div style={featureStyle.first.content}>{features.first.content}</div>
      <img
        style={featureStyle.first.image}
        src={features.first.image}
        alt={features.first.image}
      />
      <div style={featureStyle.first.points}>
        {features.first.point.map((string, index) => {
          return <Pill radius={15} index={index} content={string} />;
        })}
      </div>
    </div>
  );
};

class KeyFeatures extends React.Component {
  render() {
    return (
      <div>
        <div id={"key_feature_header"} style={keyFeatureStyle.header}>
          Key Features
        </div>
        <div id={"key_feature_subheader"} style={keyFeatureStyle.content}>
          Check out what AIM can do
        </div>
        <div
          id={"feature_blocks"}
          style={{
            flexWrap: "wrap",
            display: "inline-flex",
            marginLeft: "16.5rem",
            marginRight: "16.5rem",
            marginBottom: "5rem",
            marginTop: "8.58rem",
          }}
        >
          {key_feature.map((feature) => {
            return (
              <KeyFeatureBlock
                icon={feature.icon}
                title={feature.title}
                content={feature.content}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

const KeyFeatureBlock = (props) => {
  return (
    <div style={keyFeatureStyle.featureBlock}>
      <div
        style={{
          margin: "auto",
          marginTop: "3rem",
          marginBottom: "3rem",
          width: "fit-content",
        }}
      >
        <IconSVG name={props.icon} color={"#E4EBED"} size={1.33} />
      </div>
      <div style={keyFeatureStyle.featureTitle}>{props.title}</div>
      <div style={keyFeatureStyle.featureContent}>{props.content}</div>
    </div>
  );
};

const Pill = (props) => {
  let width = props.width ?? 14;
  let height = props.height ?? 3.42;
  let index = props.index;
  let base_degree = 30;
  let radius = props.radius ?? 10;
  let degree = base_degree * index;
  let x = Math.sin((degree / 180) * 3.1415) * radius * 2.5 - width / 2;
  let y = Math.cos((degree / 180) * 3.1415) * radius * 1 - height / 2;
  return (
    <div
      style={{
        width: width + "rem",
        height: height + "rem",
        borderRadius: "2rem",
        backgroundColor: "rgba(13,140,228,0.1)",
        position: "absolute",
        left: x + "rem",
        top: y + "rem",
        fontSize: "1.33rem",
        lineHeight: "2.42rem",
        fontWeight: 600,
        letterSpacing: 0,
        fontFamily: "Roboto",
        color: "#8592A7",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {props.content}
    </div>
    // <div style={{width:"1rem", height:"1rem", backgroundColor:"rgba(13,140,228,1)", position:"absolute", left:x+"rem", top:y+"rem"}}>{props.index}</div>
  );
};

export { Feature };
