import React from "react";
import { contactUs, thankYou } from "../string";
import { contactUsStyle, thankYouStyle } from "../landingPageStyle";
import { ButtonSolid } from "@aim-mf/styleguide";

class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      company: "",
      email: "",
      contact: "",
      submitted: false,
      submitted_failed: false,
    };
  }

  render() {
    if (this.state.submitted === true) {
      return <SucceedPage onPageChange={this.props.onPageChange} />;
    }
    return (
      <div>
        <div
          style={{
            height: "68.33rem",
            display: "flex",
            position: "relative",
            backgroundColor: "#E4E4E4",
            overflowX: "hidden",
          }}
        >
          <div
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              marginLeft: "21rem",
              marginTop: "3.75rem",
            }}
          >
            <div style={contactUsStyle.header}>{contactUs.header}</div>
            <div style={contactUsStyle.content}>{contactUs.content}</div>
            {Object.keys(contactUs.fields).map((field) => {
              return (
                <InputField
                  key={field}
                  textStyle={contactUsStyle.fieldText}
                  inputStyle={contactUsStyle.fieldInput}
                  title={contactUs.fields[field]}
                  name={field}
                  onChange={(key, value) => {
                    this.setState({ [key]: value });
                  }}
                />
              );
            })}
            <div>
              <ButtonSolid
                name={"Submit"}
                width={"25.83rem"}
                height={"3.17rem"}
                clickEvent={() => this.onSubmit(this.state, this.props.email)}
              />
            </div>
          </div>

          <div>
            <img
              style={contactUsStyle.image}
              src={contactUs.image}
              alt={contactUs.image}
            />
          </div>
        </div>
      </div>
    );
  }

  onSubmit = (filledData, sendEmailFunction) => {
    let email_body =
      "There is a new submission from the AIM demo website 'https://aim.kpmg.com.sg/'.\nThe information is shown below:\n\n";
    Object.keys(filledData).map((key) => {
      if (key !== "submitted" && key !== "submitted_failed") {
        email_body = email_body + key + " : " + filledData[key] + ".\n";
      }
    });
    let data = {
      subject: "New AIM request",
      // body_type: Optional[str] = "Plain",
      body: email_body,
      recipients: [
        "weidu@kpmg.com.sg",
        "eang@kpmg.com.sg",
        "jiajielu@kpmg.com.sg",
      ],
      // sender: Optional[EmailStr],
      // cc_recipients: ["eang@kpmg.com.sg", "jiajielu@kpmg.com.sg"],
    };
    sendEmailFunction(
      data,
      () => {
        this.setState({ submitted: true });
      },
      () => {
        this.setState({ submitted_failed: true });
      }
    );
  };
}

const SucceedPage = (props) => {
  return (
    <div style={{ backgroundColor: "#E4E4E4" }}>
      <div style={thankYouStyle.header}>{thankYou.header}</div>
      <div style={thankYouStyle.content}>{thankYou.content}</div>
      <div style={thankYouStyle.button}>
        <ButtonSolid
          width={"25.75rem"}
          height={"3.17rem"}
          name={thankYou.buttonText}
          clickEvent={() => {
            props.onPageChange("HOME");
          }}
        />
      </div>
    </div>
  );
};

const InputField = (props) => {
  return (
    <div style={{ marginBottom: "2rem" }}>
      <div style={props.textStyle}>{props.title}</div>
      <input
        style={props.inputStyle}
        onChange={(event) => {
          props.onChange(props.name, event.target.value);
        }}
      />
    </div>
  );
};

export { ContactUs };
