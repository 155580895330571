import React from "react";
import { Tab } from "./component/Tab";
import { headerHomeImage, pageLabels } from "../string";
import { ButtonSolid } from "@aim-mf/styleguide";
import { navigateToUrl } from "single-spa";

class Header extends React.Component {
  render() {
    return (
      <div style={{ height: "5rem", display: "flex" }}>
        <div style={{ width: "20rem" }}>
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <img
            id={"logo"}
            style={{ marginLeft: "4.08rem", height: "100%" }}
            src={headerHomeImage}
            alt={headerHomeImage}
            onClick={() => {
              this.props.onPageChange("HOME");
            }}
          />
        </div>
        <Tab
          OnTabChange={this.props.onPageChange}
          tabWidth={"10rem"}
          height={"100%"}
          labels={pageLabels}
          value={this.props.page}
        />
        <div
          id={"loginButton"}
          style={{ marginTop: "1.5rem", marginRight: "2rem" }}
        >
          <ButtonSolid
            name={"Log in"}
            clickEvent={() => {
              navigateToUrl("/login");
            }}
          />
        </div>
      </div>
    );
  }
}

export { Header };
