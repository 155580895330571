import React from "react";
import { case_studies } from "../string";
import { caseStudiesImageStyle, caseStudiesStyle } from "../landingPageStyle";

class CaseStudies extends React.Component {
  render() {
    return (
      <div>
        <Cases
          item={case_studies.first}
          itemStyle={caseStudiesStyle}
          imageStyle={caseStudiesImageStyle.first}
          imagePosition={"right"}
          backgroundColor={"#FAFAFA"}
          textMarginTop={"7rem"}
        />
        <Cases
          item={case_studies.second}
          itemStyle={caseStudiesStyle}
          imageStyle={caseStudiesImageStyle.second}
          imagePosition={"left"}
          backgroundColor={"#FFFFFF"}
          textMarginTop={"18.67rem"}
        />
        <Cases
          item={case_studies.third}
          itemStyle={caseStudiesStyle}
          imageStyle={caseStudiesImageStyle.third}
          imagePosition={"right"}
          backgroundColor={"#FAFAFA"}
          height={"87.33rem"}
          textMarginTop={"12.83rem"}
        />
      </div>
    );
  }
}

const Cases = (props) => {
  return (
    <div
      style={{
        height: props.height ?? "67rem",
        display: "flex",
        backgroundColor: props.backgroundColor,
      }}
    >
      {props.imagePosition === "left" && (
        <img
          style={props.imageStyle}
          src={props.item.image}
          alt={props.item.image}
        />
      )}
      <div
        style={{
          width: "52.25rem",
          marginLeft: "12.33rem",
          marginTop: props.textMarginTop,
        }}
      >
        <Pill color={props.item.pillColor} content={"Case Study"} />
        <div style={props.itemStyle.title}>{props.item.title}</div>
        <div style={props.itemStyle.subTitle}>{props.item.subTitle}</div>
        <div style={props.itemStyle.content}>{props.item.content}</div>
      </div>
      {props.imagePosition === "right" && (
        <img
          style={props.imageStyle}
          src={props.item.image}
          alt={props.item.image}
        />
      )}
    </div>
  );
};

const Pill = (props) => {
  return (
    <div
      style={{
        backgroundColor: props.color,
        color: "white",
        borderRadius: "2rem",
        width: "10rem",
        height: "2rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "1rem",
      }}
    >
      {props.content}
    </div>
  );
};
export { CaseStudies };
