import React from "react";
import { Home } from "./page/home";
import { Header } from "./page/header";
import { Feature } from "./page/feature";
import { CaseStudies } from "./page/caseStudies";
import { Pricing } from "./page/pricing";
import { AboutUs } from "./page/aboutUs";
import { ContactUs } from "./page/ContactUs";
import { footNote, pageLabels } from "./string";

import {
  fetchAdminAPIWithPermission,
  fetchAPIWithPermission,
  usernameLogin,
} from "@aim-mf/api";
import { sendEmailAPIWithPermission } from "@aim-mf/api";

const handleLogOut = () => {
    const subscription = fetchAdminAPIWithPermission("auth/logout").subscribe(
        (results) => {
            sessionStorage.removeItem("CurrentUser");
            sessionStorage.removeItem("MyProfile");
            sessionStorage.removeItem("jwt");
            sessionStorage.removeItem("nav");
            sessionStorage.removeItem("riskList");
            sessionStorage.removeItem("type_object_list");
            sessionStorage.removeItem("alerted_risk");
            sessionStorage.removeItem("alerted_object_id");
            sessionStorage.removeItem("alert_type");
        },
        (err) => {
        }
    )
  return () => subscription.unsubscribe();
};

const sendEmail = (data, success, failed) => {
  // need to login an email sending account to send email
  const loginSubscription = usernameLogin("emailNotification", "369@kpmg#DA", "aim", false).subscribe(
    (results) => {
      // after log in, need to send email
      const subscription = sendEmailAPIWithPermission("notification/email", {
        data: data,
      }).subscribe(
        (results) => {
          success();
          // after sending email, logout the account
          handleLogOut();
        },
        (err) => {
          console.log(err); // eslint-disable-line
          failed();
          // if failed, also need to log out
          handleLogOut();
        }
      );
      return () => subscription.unsubscribe();
    },
    (err) => {
      // log in failed
      console.log("login failed"); // eslint-disable-line
    }
  );
  return () => loginSubscription.unsubscribe();
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "HOME",
    };
  }

  render() {
    return (
      <div
        style={{
          backgroundColor: "white",
          minHeight: "100vh",
          height: "100%",
          width: "100%",
        }}
      >
        <Header onPageChange={this.onPageChange} page={this.state.page} />
        {this.state.page === "HOME" && (
          <Home onPageChange={this.onPageChange} />
        )}
        {this.state.page === pageLabels[0] && <Feature />}
        {this.state.page === pageLabels[1] && <CaseStudies />}
        {this.state.page === pageLabels[2] && <Pricing />}
        {this.state.page === pageLabels[3] && <AboutUs />}
        {this.state.page === pageLabels[4] && (
          <ContactUs email={sendEmail} onPageChange={this.onPageChange} />
        )}
        <div
          id="footNote"
          style={{
            color: "white",
            padding: "7rem 25rem 5rem 25rem",
            height: "30rem",
            backgroundColor: "#4E565E",
            display: "flex",
            width: "100%",
            margin: "auto",
            flexWrap: "wrap",
          }}
        >
          <div style={{ width: "100%" }}>{footNote[0]}</div>
          <div style={{ width: "100%" }}>{footNote[1]}</div>
          <div style={{ width: "100%" }}>{footNote[2]}</div>
        </div>
      </div>
    );
  }

  onPageChange = (page) => {
    this.setState({ page: page });
  };
}

export { App };
