import React from "react";
import { mapping } from "@aim-mf/styleguide";

// ===========   Tab Component ==========
// need props of:
// OnTabChange (callback function)
// height (string with 'px')
// width ||  tabWidth (stirng with 'px')

class Tab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabWidth: this.props.tabWidth,
      height: this.props.height,
      width: this.props.width,
      labels: this.props.labels,
      value: this.props.value ? this.props.value : this.props.labels[0],
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (
      JSON.stringify(this.props.labels) !== JSON.stringify(nextProps.labels)
    ) {
      this.setState({ labels: nextProps.labels });
    }
    if (JSON.stringify(this.props.value) !== JSON.stringify(nextProps.value)) {
      this.setState({ value: nextProps.value });
    }
  }

  render() {
    const TabLabelNumber = this.state.labels.length;
    const TabWidth =
      this.state.tabWidth ||
      parseInt(this.state.width.split("rem")[0]) / TabLabelNumber + "rem";

    var Tab_Div = {
      height: this.state.height,
      width: this.state.width || "100%",
      position: "relative",
    };

    var Tab_Label = {
      height: this.state.height,
      width: TabWidth,
      backgroundColor: mapping["Color_Extra_Transparent"],
      border: "none",
      outline: "none",
      padding: "0px",
      marginTop: "0.5rem",
    };

    var Tab_Indicator = {
      height: "5px",
      width: TabWidth,
      position: "absolute",
      bottom: "0px",
    };

    const HandleTabChange = (event) => {
      event.persist();
      let newName = event.target.id;
      this.setState({ value: newName });
      this.props.OnTabChange(newName);
    };

    return (
      <div style={Tab_Div}>
        {this.state.labels.map((label, index) => {
          var fontStyle = {};
          var Tab_Indicator_Color = "";
          if (label === this.state.value) {
            fontStyle = {
              ...mapping["heading/h6/mutedcenter"],
              color: mapping["Color_Tab_Unselected_Font"],
            };
            Tab_Indicator_Color = {
              backgroundColor: mapping["Color_Basic_Primary"],
            };
          } else {
            fontStyle = {
              ...mapping["heading/h6/mutedcenter"],
              color: mapping["Color_Tab_Unselected_Font"],
            };
            Tab_Indicator_Color = {
              backgroundColor: mapping["Color_Extra_Transparent"],
            };
          }
          return (
            <button
              id={label}
              key={index}
              style={Tab_Label}
              onClick={HandleTabChange}
            >
              <span id={label} style={fontStyle}>
                {label}
              </span>
              <div
                id={label}
                style={Object.assign({}, Tab_Indicator, Tab_Indicator_Color)}
              />
            </button>
          );
        })}
      </div>
    );
  }
}

class Tab_BookMark extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabWidth: this.props.tabWidth,
      height: this.props.height,
      width: this.props.width,
      labels: this.props.labels,
      CurrentId: 0,
      padding: this.props.padding,
    };
  }

  render() {
    const TabLabelNumber = this.state.labels.length;
    const TabWidth =
      this.state.tabWidth ||
      parseInt(this.state.width.split("p")[0]) / TabLabelNumber + "px";

    var Tab_Div = {
      height: this.state.height,
      width: this.state.width || "100%",
      position: "relative",
    };

    var Tab_Label = {
      height: this.state.height,
      width: TabWidth,
      backgroundColor: mapping["Color_Extra_Transparent"],
      border: "none",
      outline: "none",
      padding: this.state.padding || "0",
    };

    const HandleTabChange = (event) => {
      const newId = event.target.id;
      const newName = event.target.getAttribute("name");
      this.setState({ CurrentId: newId });
      this.props.OnTabChange(newName);
    };

    return (
      <div style={Tab_Div}>
        {this.state.labels.map((label, index) => {
          let fontStyle = {};
          let backgroundStyle = {};
          if (index == this.state.CurrentId) {
            fontStyle = mapping["heading/h6/lightcenter"];
            backgroundStyle = this.props.activeBackgroundColor
              ? this.props.activeBackgroundColor
              : "rgb(33,41,56)";
          } else {
            fontStyle = {
              ...mapping["heading/h6/mutedcenter"],
              color: mapping["Color_Tab_Unselected_Font"],
            };
            backgroundStyle = mapping["Color_Extra_Transparent"];
          }

          return (
            <button
              id={index}
              key={index}
              name={label}
              style={Object.assign({}, Tab_Label, {
                backgroundColor: backgroundStyle,
              })}
              onClick={HandleTabChange}
            >
              <span id={index} name={label} style={fontStyle}>
                {label}
              </span>
            </button>
          );
        })}
      </div>
    );
  }
}

export { Tab, Tab_BookMark };
