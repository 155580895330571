import React from "react";
import { homePageNotes } from "../string";
import {
  homePageStyle,
  homePageHeaderStyle,
  homePageContentStyle,
  homePageSubtitleStyle,
  homePageTitleStyle,
} from "../landingPageStyle";
import { ButtonSolid } from "@aim-mf/styleguide";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      floatButtonLeft: "2rem",
    };
  }

  componentDidMount() {
    // floatButtonAnimation(()=>this.setState({floatButtonLeft:"2.5rem"}), ()=>{this.setState({floatButtonLeft:"2rem"})})
  }

  render() {
    return (
      <div>
        <div
          id={"floating_button"}
          style={{
            position: "fixed",
            bottom: "3rem",
            zIndex: 1,
            left: this.state.floatButtonLeft,
            transition: "left 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.725)",
          }}
          // onMouseEnter={() => {
          //   this.setState({ floatButtonLeft: 0 });
          // }}
          // onMouseLeave={() => {
          //   this.setState({ floatButtonLeft: "-8.5rem" });
          // }}
        >
          <ButtonSolid
            name={"Request a demo"}
            width={"9rem"}
            height={"3rem"}
            clickEvent={() => {
              this.props.onPageChange("CONTACT US");
            }}
          />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            position: "relative",
            justifyContent: "flex-end",
            backgroundColor: "#F7F8F9",
          }}
        >
          <img
            style={homePageStyle.first.image}
            src={homePageNotes.first.image}
            alt={homePageNotes.first.image}
          />
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              marginLeft: "21rem",
              marginTop: "5.17rem",
            }}
          >
            <div style={homePageHeaderStyle}>{homePageNotes.first.header}</div>
            <div style={homePageTitleStyle}>{homePageNotes.first.title}</div>
            <div style={homePageSubtitleStyle}>
              {homePageNotes.first.subtitle}
            </div>
            <div style={homePageContentStyle}>
              {homePageNotes.first.content}
            </div>
          </div>
        </div>
        <div style={{ height: "fit-content" }}>
          <img
            style={homePageStyle.second.image}
            src={homePageNotes.second.image}
            alt={homePageNotes.second.image}
          />
          <div style={{ width: "100%" }}>
            <div style={homePageStyle.second.header}>
              {homePageNotes.second.header}
            </div>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            position: "relative",
            overflowX: "hidden",
            background: "linear-gradient(157.23deg, #F1F5ED 0%, #DFE7D7 100%)",
          }}
        >
          <img
            style={homePageStyle.third.image}
            src={homePageNotes.third.image}
            alt={homePageNotes.third.image}
          />
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              marginLeft: "56.36rem",
              marginTop: "8.67rem",
            }}
          >
            <div style={homePageStyle.third.header}>
              {homePageNotes.third.header}
            </div>
            <div style={homePageStyle.third.content}>
              {homePageNotes.third.content}
            </div>
          </div>
        </div>
        <div
          style={{
            height: "63.92rem",
            width: "100%",
            display: "flex",
            position: "relative",
            justifyContent: "flex-end",
            backgroundColor: "#F4F4F4",
          }}
        >
          <img
            style={homePageStyle.fourth.image}
            src={homePageNotes.fourth.image}
            alt={homePageNotes.fourth.image}
          />
          <div
            style={{
              mixBlendMode: "multiply",
              position: "absolute",
              top: 0,
              left: 0,
              marginLeft: "10.5rem",
              marginTop: "33rem",
              height: "24.5rem",
              backgroundColor: "#195DAC",
              padding: "2.42rem",
              paddingLeft: "6.33rem",
              width: "calc(100% - 10.5rem)",
            }}
          >
            <div style={homePageStyle.fourth.header}>
              {homePageNotes.fourth.header}
            </div>
            <div style={homePageStyle.fourth.content}>
              {homePageNotes.fourth.content}
            </div>
          </div>
        </div>
        <div style={{ height: "fit-content" }}>
          <img
            style={homePageStyle.second.image}
            src={homePageNotes.fifth.image}
            alt={homePageNotes.fifth.image}
          />
          <div style={{ width: "100%" }}>
            <div style={homePageStyle.second.header}>
              {homePageNotes.fifth.header}
            </div>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            position: "relative",
            justifyContent: "flex-end",
            backgroundColor: "#F4F4F4",
          }}
        >
          <img
            style={homePageStyle.sixth.image}
            src={homePageNotes.sixth.image}
            alt={homePageNotes.sixth.image}
          />
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              marginLeft: "15rem",
              marginTop: "19rem",
            }}
          >
            <div style={homePageStyle.sixth.header}>
              {homePageNotes.sixth.header}
            </div>
            <div style={homePageStyle.sixth.content}>
              {homePageNotes.sixth.content}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            background: "linear-gradient(180deg, #EFFCFF 0%, #99D9E9 100%)",
          }}
        >
          <img
            style={homePageStyle.seventh.image}
            src={homePageNotes.seventh.image}
            alt={homePageNotes.seventh.image}
          />
          <div style={{ marginTop: "8rem" }}>
            <div style={homePageStyle.seventh.header}>
              {homePageNotes.seventh.header}
            </div>
            <div style={homePageStyle.seventh.title}>
              {homePageNotes.seventh.title}
            </div>
            <div style={homePageStyle.seventh.content}>
              {homePageNotes.seventh.content}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function floatButtonAnimation(setLeft, clearLeft) {
  setTimeout(() => {
    //console.log("set");
    setLeft();
    clearFloatButtonAnimation(setLeft, clearLeft);
  }, 5000);
}

function clearFloatButtonAnimation(setLeft, clearLeft) {
  setTimeout(() => {
    //console.log("clear");
    clearLeft();
    floatButtonAnimation(setLeft, clearLeft);
  }, 500);
}

export { Home };
