import {
  badminton,
  baseball,
  basketball,
  diving,
  fencing,
  fencingAction,
  football,
  golfBall,
  golfGirl,
  redCard,
  yoga,
  yogaAction,
  winCelebration,
  dashboard,
  login,
  task,
  riskMatrix,
  riskReg,
  kpmgLogo,
  indoor,
} from "@aim-mf/styleguide";

const headerHomeImage = kpmgLogo;

const pageLabels = [
  "FEATURES",
  "CASE STUDIES",
  "PRICING",
  "ABOUT US",
  "CONTACT US",
];

const footNote = [
  "© 2021 Copyright owned by one or more of the KPMG International entities. KPMG International entities provide no services to clients. All rights reserved.",
  "KPMG refers to the global organization or to one or more of the member firms of KPMG International Limited (“KPMG International”), each of which is a separate legal entity. KPMG International Limited is a private English company limited by guarantee and does not provide services to clients. For more detail about our structure please visit https://home.kpmg/governance.",
  "Member firms of the KPMG network of independent firms are affiliated with KPMG International. KPMG International provides no client services. No member firm has any authority to obligate or bind KPMG International or any other member firm vis-à-vis third parties, nor does KPMG International have any such authority to obligate or bind any member firm.",
];

const homePageNotes = {
  first: {
    header: "Never drop the ball",
    title: "with AI Enabled Monitoring (AIM)",
    subtitle: "KPMG's rapid data analytics development framework",
    content: "Control everything within your organisation with AI",
    image: basketball,
  },
  second: {
    header:
      "Spot anomalies, escalate issues early, close them automatically, all from one simple platform",
    title: "",
    subtitle: "",
    content: "",
    image: dashboard,
  },
  third: {
    header: "Don't worry about clean data",
    title: "",
    subtitle: "",
    content:
      "KPMG AIM uses artificial intelligence and natural language processing to make sense of unstructured data, identify data gaps, inconsistencies and anomalies",
    image: yoga,
  },
  fourth: {
    header: "Save a fortune in costs every year",
    title: "",
    subtitle: "",
    content:
      "Spot anomalies, escalate issues early, close them automatically, all from one simple platform",
    image: football,
  },
  fifth: {
    header: "Get alerted automatically when something goes wrong",
    title: "",
    subtitle: "",
    content: "",
    image: task,
  },
  sixth: {
    header: "Save weeks of work every year",
    title: "",
    subtitle: "",
    content:
      "Receive risk alerts and access the supporting information through the same platform. Save time processing data, reduce errors and improve quality of your risk management.",
    image: badminton,
  },
  seventh: {
    header: "Get a demo.",
    title: "See for yourself all the great things that AIM can do.",
    subtitle: "",
    content:
      " • Monitor your entire business\n • Manage all your risk\n • All from one simple platform\n • Customised for you",
    image: login,
  },
};

const key_feature = [
  {
    icon: "landingPageAI",
    title: "AI Enabled",
    content:
      "Implement new or adapt existing AI models to track and predict your business",
  },
  {
    icon: "landingPageConnect",
    title: "Connectivity",
    content:
      "Connect to any of your existing systems with our database and ERP connectors",
  },
  {
    icon: "landingPageChatbot",
    title: "Automated Questionnaires",
    content:
      "Collect data from users automatically. No more consolidating excels",
  },
  {
    icon: "landingPageClean",
    title: "Data Cleaning",
    content:
      "Validate data continuously and clean data automatically where possible",
  },
  {
    icon: "landingPageWorkFlow",
    title: "Customised Workflow",
    content: "Fully customised system to cater all your business needs",
  },
  {
    icon: "landingPageAlert",
    title: "Case Management",
    content: "Manage alerts generated via the case management system",
  },
  {
    icon: "landingPageWebScraping",
    title: "Web Scraping",
    content: "Ingest data from external websites with our web scrapers",
  },
  {
    icon: "landingPageEmailAlert",
    title: "Email Alerts",
    content: "Get reminders and alerts automatically sent to your inbox",
  },
  {
    icon: "landingPageData",
    title: "Data Pipeline",
    content: "Automate data extractions and transformations",
  },
];

const features = {
  first: {
    header: "AIM is flexible and works \n" + "with all types of business",
    content:
      "Easily import data from any system \n" +
      "across your entire supply chain.",
    point: [
      "Manufacturing",
      "Distribution",
      "Sales",
      "Finance",
      "Technology",
      "Customers",
      "Employees",
      "Warehousing",
      "Logistics",
      "Energy",
      "Raw materials",
      "Suppliers",
    ],
    image: yogaAction,
  },
  second: {
    header:
      "Super friendly AI \n" +
      "that integrates easily \n" +
      "with all your applications.",
    content:
      "Get more done by easily integrating AIM \n" +
      "with the tools you already use.",
    image: fencing,
  },
};

const about_us = {
  title: "Keep fraud in check with KPMG.",
  content:
    "KPMG Forensic is one of the leading forensic practice in Asia Pacific. From our office in Hong Leong Building, we help clients solve one problem at a time by either providing or inventing new services and technologies.",
  buttonText: "Visit KPMG hoomepage",
  image: fencingAction,
  backgroundImage: indoor,
};

const pricing = {
  title: "No hefty license fees.",
  content:
    "With AIM, you get a solution fully customised to your needs and a lifetime access to everything you’ve paid for.",
  points: [
    "• \tLifetime access to the source codes\n",
    "• \tCustomised price and solution\n",
    "• \tMaintenance and support available on demand.\n",
  ],
  image: golfBall,
};

const case_studies = {
  first: {
    pillColor: "rgba(74,73,142,0.4)",
    title: "Regulatory Compliance Monitoring",
    subTitle: "Indonesian Government",
    content:
      "Customised and deployed a regulatory compliance monitoring solution using AIM, which is rolled out to five entities.\n" +
      "\nThe solution has been enhanced to include litigation monitoring, to assist the client in tracking all litigation cases across all subsidiaries which include several public listed companies. \n" +
      "\nIn the next phase, the solution will be further extended to monitor contracts.",
    image: dashboard,
  },
  second: {
    pillColor: "rgba(192,34,104,0.4)",
    title: "Enterprise Risk Management",
    subTitle: "Autonomous university in Singapore",
    content:
      "Customised and deployed and Enterprise Risk Management solution using AIM to digitise the client's enterprise risk management process. The three lines of defence are also aligned using the solution coupled with control self assessments.",
    image: riskMatrix,
  },
  third: {
    pillColor: "rgba(0,189,120,0.4)",
    title: "Business Intelligence and Monitoring",
    subTitle: "Oil & gas engineering company",
    content:
      "Customised and deployed AIM to create a common data layer for the client to link their accounting ERP, manufacturing systems and spreadsheets.\n" +
      "\nCustomised dashboards are designed and created to help management improve accuracy and monitoring of key financial figures to help decision making.\n" +
      "\nSpecific process analysis will also be implemented in further phases to identify risk indicators in the various key processes that have a direct impact to revenue and costs, in order to improve cost effectiveness and efficiency and potentially identify fraud.",
    image: riskReg,
  },
};

const contactUs = {
  header: "Touch base with us",
  content:
    "We would love to have a chat with you!\n" +
    "Simply provide your details below and we will be in touch shortly.",
  fields: {
    name: "Name",
    company: "Company Name",
    email: "Email Address",
    contact: "Contact Number",
  },
  image: baseball,
  buttonString: "Submit",
};

const thankYou = {
  header: "Thank you",
  content:
    "Your enquiry was successfully sent!\n" +
    "A KPMG representative will get in touch with you soon!",
  buttonText: "Back to home",
};

export {
  pageLabels,
  footNote,
  key_feature,
  about_us,
  pricing,
  case_studies,
  homePageNotes,
  features,
  contactUs,
  headerHomeImage,
  thankYou,
};
