const homePageStyle = {
  first: {
    image: {
      width: "68.9rem",
      height: "51.6rem",
      marginRight: "3.9725rem",
      mixBlendMode: "multiply",
    },
  },
  second: {
    header: {
      width: "61.83rem",
      color: "#343A40",
      fontFamily: "Roboto",
      fontSize: "4rem",
      fontWeight: 600,
      letterSpacing: 0,
      lineHeight: "5.42rem",
      textAlign: "center",
      margin: "auto",
      paddingBottom: "6.83rem",
    },
    image: {
      width: "56.83rem",
      margin: "auto",
      marginBottom: "6.83rem",
      mixBlendMode: "multiply",
      boxShadow: "0 0.833rem 1.67rem 0 rgba(0,0,0,0.5)",
      marginTop: "5rem",
    },
  },
  third: {
    header: {
      width: "39.67rem",
      color: "#000000",
      fontFamily: "Roboto",
      fontSize: "4.67rem",
      fontWeight: 600,
      letterSpacing: 0,
      lineHeight: "6.33rem",
      marginBottom: "4.85rem",
    },
    content: {
      width: "39.67rem",
      color: "#000000",
      fontFamily: "Roboto",
      fontSize: "1.5rem",
      fontWeight: 600,
      letterSpacing: "2px",
      lineHeight: "2.42rem",
    },
    image: {
      width: "157.81rem",
      mixBlendMode: "multiply",
    },
  },
  fourth: {
    header: {
      lineHeight: "5.33rem",
      width: "39.67rem",
      color: "#FFFFFF",
      textAlign: "center",
      fontSize: "4.67rem",
      marginBottom: "2rem",
    },
    content: {
      width: "33.92rem",
      color: "#FFFFFF",
      fontFamily: "Roboto",
      fontSize: "1.5rem",
      fontWeight: 600,
      letterSpacing: "2px",
      lineHeight: "2.42rem",
      textAlign: "center",
      marginLeft: "3rem",
    },
    image: {
      mixBlendMode: "multiply",
    },
  },
  sixth: {
    header: {
      lineHeight: "6.33rem",
      width: "48rem",
      color: "#000000",
      textAlign: "end",
      fontSize: "4.67rem",
      marginBottom: "4rem",
    },
    content: {
      width: "39.74rem",
      color: "#000000",
      fontFamily: "Roboto",
      fontSize: "1.5rem",
      fontWeight: 600,
      letterSpacing: "1.5px",
      lineHeight: "2.42rem",
      marginLeft: "8.33rem",
      textAlign: "end",
    },
    image: {
      width: "43.04rem",
      height: "63.5rem",
      marginRight: "14.71rem",
      mixBlendMode: "multiply",
    },
  },
  seventh: {
    header: {
      marginLeft: "2.5rem",
      marginBottom: "2rem",
      lineHeight: "4.5rem",
      width: "27.25rem",
      color: "#111111",
      textAlign: "center",
      fontSize: "4.67rem",
    },
    title: {
      lineHeight: "2.75rem",
      letterSpacing: "1.33px",
      width: "30.5rem",
      color: "#7692A9",
      textAlign: "center",
      fontSize: "2rem",
      marginBottom: "3rem",
    },
    content: {
      width: "33.33rem",
      color: "#000000",
      fontFamily: "Roboto",
      fontSize: "1.5rem",
      fontWeight: 600,
      letterSpacing: "2px",
      lineHeight: "1.5em",
      textAlign: "start",
      whiteSpace: "pre-wrap",
      marginLeft: "2rem",
    },
    image: {
      height: "24rem",
      margin: "7.58rem 6.35rem 16.67rem 16.42rem",
      mixBlendMode: "multiply",
      boxShadow: "0 0.833rem 1.67rem 0 rgba(0,0,0,0.5)",
    },
  },
};

const homePageHeaderStyle = {
  color: "#343A40",
  fontFamily: "Roboto",
  fontSize: "4.42rem",
  fontWeight: 600,
  letterSpacing: 0,
  lineHeight: "6.92rem",
  marginBottom: "2rem",
};
const homePageTitleStyle = {
  color: "#25BBF1",
  fontFamily: "Roboto",
  fontSize: "2.33rem",
  fontWeight: "bold",
  letterSpacing: 0,
  lineHeight: "3.33rem",
  marginBottom: "1.5rem",
};
const homePageSubtitleStyle = {
  color: "#25BBF1",
  fontFamily: "Roboto",
  fontSize: "1.83rem",
  letterSpacing: "0.29px",
  lineHeight: "2.33rem",
  marginBottom: "5rem",
};
const homePageContentStyle = {
  width: "26.625rem",
  color: "#60686F",
  fontFamily: "Roboto",
  fontSize: "2rem",
  letterSpacing: 0,
  lineHeight: "3.42rem",
};

const pricingImageStyle = {
  width: "60rem",
  marginLeft: "calc(100% - 60rem)",
  mixBlendMode: "multiply",
};

const pricingTextStyle = {
  title: {
    width: "46.25rem",
    color: "#85AACF",
    fontFamily: "Roboto",
    fontSize: "2.8125rem",
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: "5.1875rem",
    marginBottom: "2rem",
  },
  content: {
    width: "34.6875rem",
    color: "#000000",
    fontFamily: "Roboto",
    fontSize: "1.125rem",
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: "1.625rem",
    marginBottom: "3rem",
  },
  point: {
    width: "28.875rem",
    color: "#000000",
    fontFamily: "Roboto",
    fontSize: "1.125rem",
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: "1.625rem",
    whiteSpace: "pre-wrap",
  },
};

const aboutUSStyle = {
  title: {
    width: "46.25rem",
    color: "#343A40",
    fontFamily: "Roboto",
    fontSize: "2.8125rem",
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: "5.1875rem",
    marginBottom: "2rem",
  },
  content: {
    width: "34.6875rem",
    color: "#000000",
    fontFamily: "Roboto",
    fontSize: "1.125rem",
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: "1.625rem",
    marginBottom: "3rem",
  },
  image: {
    width: "50rem",
    marginLeft: "calc(100% - 50rem)",
    mixBlendMode: "multiply",
  },
  backgroundImage: { position: "absolute", top: 0, left: 0 },
};

const featureStyle = {
  first: {
    header: {
      width: "57.17rem",
      color: "#111111",
      fontFamily: "Roboto",
      fontSize: "4.67rem",
      fontWeight: 600,
      letterSpacing: 0,
      lineHeight: "6.33rem",
      textAlign: "center",
      margin: "auto",
      marginTop: "6rem",
    },
    content: {
      width: "31.75rem",
      color: "#4C4C4C",
      fontFamily: "Roboto",
      fontSize: "1.5rem",
      fontWeight: 600,
      letterSpacing: "2px",
      lineHeight: "2rem",
      textAlign: "center",
      margin: "auto",
      marginTop: "3.5rem",
      marginBottom: "8.67rem",
    },
    image: { width: "38rem", margin: "auto", marginBottom: "13.84rem" },
    points: { position: "absolute", left: "59rem", top: "45rem" },
  },
  second: {
    header: {
      width: "55rem",
      color: "#111111",
      fontFamily: "Roboto",
      fontSize: "4.67rem",
      fontWeight: 600,
      letterSpacing: 0,
      lineHeight: "6.33rem",
      textAlign: "center",
      margin: "auto",
    },
    content: {
      width: "38.42rem",
      color: "#4C4C4C",
      fontFamily: "Roboto",
      fontSize: "1.5rem",
      fontWeight: 600,
      letterSpacing: "2px",
      lineHeight: "2rem",
      textAlign: "center",
      margin: "auto",
      marginTop: "3.5rem",
    },
    image: {
      width: "32.11rem",
      marginLeft: "73.71rem",
      mixBlendMode: "multiply",
    },
  },
};

const keyFeatureStyle = {
  header: {
    width: "22rem",
    color: "#343A40",
    fontFamily: "Roboto",
    fontSize: "3.33rem",
    fontWeight: "bold",
    letterSpacing: "0.53px",
    lineHeight: "5.33rem",
    textAlign: "center",
    marginLeft: "49.04rem",
    marginTop: "7.17rem",
  },
  content: {
    width: "32.08rem",
    color: "#343A40",
    fontFamily: "Roboto",
    fontSize: "2.33rem",
    fontWeight: "bold",
    letterSpacing: "0.37px",
    lineHeight: "3.83rem",
    textAlign: "center",
    marginLeft: "44rem",
  },
  featureBlock: {
    backgroundColor: "#E4EBED",
    width: "26.33rem",
    borderRadius: "1.33rem",
    height: "27.5rem",
    margin: "1rem",
    boxShadow: "0 2px 30px 1px rgba(0,0,0,0.11)",
  },
  featureIcon: {},
  featureTitle: {
    width: "22.33rem",
    margin: "auto",
    textAlign: "center",
    fontFamily: "Roboto",
    fontWeight: "600",
    fontSize: "2rem",
    letterSpacing: "0.32px",
    marginBottom: "1rem",
  },
  featureContent: {
    width: "22.33rem",
    margin: "auto",
    textAlign: "center",
    color: "#4D4B4B",
    fontFamily: "Roboto",
    fontSize: "1.5rem",
    letterSpacing: "0.24px",
    lineHeight: "2.42rem",
  },
};

const caseStudiesStyle = {
  title: {
    width: "100%",
    color: "#373737",
    fontFamily: "Roboto",
    fontSize: "3.42rem",
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: "4.62rem",
    marginBottom: "2rem",
  },
  subTitle: {
    width: "100%",
    color: "#17A2B8",
    fontFamily: "Roboto",
    fontSize: "2.33rem",
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: "3.17rem",
    marginBottom: "2rem",
  },
  content: {
    width: "47.67rem",
    color: "#5B5B5B",
    fontFamily: "Roboto",
    fontSize: "1.83rem",
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: "3.33rem",
    whiteSpace: "pre-wrap",
  },
};

const caseStudiesImageStyle = {
  first: {
    height: "27rem",
    width: "47.95rem",
    boxShadow: "0 0.83rem 1.67rem 0 rgba(0,0,0,0.5)",
    marginTop: "10rem",
    marginLeft: "2.9rem",
    marginRight: "8.33rem",
  },
  second: {
    height: "50.67rem",
    width: "38.33rem",
    boxShadow: "0 0.83rem 1.67rem 0 rgba(0,0,0,0.5)",
    marginTop: "8.92rem",
    marginLeft: "7.33rem",
    borderRadius: "2rem",
  },
  third: {
    height: "69.17rem",
    width: "45.18rem",
    boxShadow: "0 0.83rem 1.67rem 0 rgba(0,0,0,0.5)",
    marginTop: "13.58rem",
    marginLeft: "5.42rem",
  },
};

const contactUsStyle = {
  header: {
    width: "61.67rem",
    color: "#537BA3",
    fontFamily: "Roboto",
    fontSize: "3.75rem",
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: "6.92rem",
    marginBottom: "2rem",
  },
  content: {
    width: "31.75rem",
    color: "#2D2D2D",
    fontFamily: "Roboto",
    fontSize: "1.5rem",
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: "2.17rem",
    marginBottom: "2rem",
  },
  fieldText: {
    color: "#6A6A6A",
    marginBottom: "0.6rem",
  },
  fieldInput: {
    boxSizing: "border-box",
    height: "3.25rem",
    width: "25.83rem",
    border: "1px solid #CFD0D2",
    borderRadius: "0.67rem",
    backgroundColor: "rgba(255,255,255,1)",
    padding: "0.42rem 1rem 0.42rem 1rem",
    color: "#354052",
    fontSize: "1.33rem",
    lineHeight: "2.42rem",
  },
  image: {
    height: "68.33rem",
    mixBlendMode: "multiply",
    marginLeft: "50.26rem",
  },
};

const thankYouStyle = {
  header: {
    margin: "auto",
    paddingTop: "14.58rem",
    width: "61.67rem",
    color: "#537BA3",
    fontFamily: "Roboto",
    fontSize: "3.75rem",
    lineHeight: "6.92rem",
    fontWeight: 600,
    letterSpacing: 0,
    textAlign: "center",
  },
  content: {
    margin: "auto",
    marginTop: "4.17rem",
    width: "31.75rem",
    color: "#2D2D2D",
    fontFamily: "Roboto",
    fontSize: "1.5rem",
    lineHeight: "2.17rem",
    fontWeight: 600,
    letterSpacing: 0,
    textAlign: "center",
    whiteSpace: "pre-wrap",
    marginBottom: "4.17rem",
  },
  button: { margin: "auto", width: "fit-content", paddingBottom: "29.67rem" },
};

export {
  homePageStyle,
  homePageHeaderStyle,
  homePageTitleStyle,
  homePageSubtitleStyle,
  homePageContentStyle,
  pricingImageStyle,
  pricingTextStyle,
  aboutUSStyle,
  featureStyle,
  caseStudiesStyle,
  caseStudiesImageStyle,
  contactUsStyle,
  keyFeatureStyle,
  thankYouStyle,
};
