import React from "react";
import { about_us } from "../string";
import { aboutUSStyle } from "../landingPageStyle";
import { ButtonSolid } from "@aim-mf/styleguide";
import { navigateToUrl } from "single-spa";

class AboutUs extends React.Component {
  render() {
    return (
      <div style={{ position: "relative", minHeight: "51rem" }}>
        <img
          style={aboutUSStyle.image}
          src={about_us.image}
          alt={about_us.image}
        />
        <img
          style={aboutUSStyle.backgroundImage}
          src={about_us.backgroundImage}
          alt={about_us.backgroundImage}
        />
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            marginLeft: "20rem",
            marginTop: "6rem",
          }}
        >
          <div style={aboutUSStyle.title}>{about_us.title}</div>
          <div style={aboutUSStyle.content}>{about_us.content}</div>
          <div id={"homepage_button"}>
            <ButtonSolid
              width={"25.75rem"}
              name={"Visit KPMG homepage"}
              clickEvent={() => {
                window.open("https://home.kpmg/sg/en/home.html", "_blank");
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export { AboutUs };
